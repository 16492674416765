import React, { useState } from "react"
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
const NavBar = () => {

  const [nav, setNav] = useState(false);

  const links = [
    {
      id: 1,
      link: 'home'
    },
    {
      id: 2,
      link: 'about'
    },
    {
      id: 3,
      link: 'portfolio'
    },
    {
      id: 4,
      link: 'experience'
    },
    {
      id: 5,
      link: 'contact'
    }
  ]


  return (
    <>
      <div className="absolute top-0 bg-black w-full h-[88px]"></div>
      <div className="px-12 sticky bg-transparent pt-2 top-0 z-40">
        <div className="flex justify-between items-center h-20 px-8 text-white bg-gray-800 bg-opacity-80 backdrop-filter backdrop-blur-sm rounded-full">

          <div>
            <h1 className="text-5xl font-signature ml-1 mt-3 mb-2 ">Zeel</h1>
          </div>

          <ul className="hidden md:flex items-center">
            {links.map((navbartech) => (

              <li key={navbartech.id} className="  px-4 cursor-pointer capitalize font-semibold text-gray-500 hover:scale-110 duration-200">

                <Link to={navbartech.link} smooth duration={500}>{navbartech.link}</Link>
              </li>

            )
            )}

            <a href="https://www.buymeacoffee.com/zeel0369" class="relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md">
              <span class="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#fb1c1c] to-[#ead21e] group-hover:from-[#eb3d2a] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
              <span class="relative px-2 py-1  transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400">
                <span class="relative text-white">Buy Me Coffee</span>
              </span>
            </a>

          </ul>

          <div onClick={() => setNav(!nav)} className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden">
            {nav ? <FaTimes size={30} /> : <FaBars size={30} />}
          </div>

          {
            nav && (
              <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full min-h-screen bg-gradient-to-b from-black to-gray-800 text-gray-500 ">

                {links.map(({ id, link }) => (

                  <li key={id} className="px-4 cursor-pointer capitalize py-6 font-semibold">
                    <Link onClick={() => setNav(!nav)} to={link} smooth duration={500}>{link}</Link>

                  </li>

                )
                )}
                <a href="https://www.buymeacoffee.com/zeel0369" class="relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md">
                  <span class="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#fb1c1c] to-[#ead21e] group-hover:from-[#eb3d2a] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                  <span class="relative px-3 py-1  transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400">
                    <span class="relative text-white">Buy Me Coffee</span>
                  </span>
                </a>

              </ul>
            )
          }
        </div>
      </div>
    </>
  )
}

export default NavBar;
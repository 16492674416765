import React from 'react'
import arrayDestruct from "../assets/portfolio/android.png";


const Portfolio = () => {

    const portfolios = [
        {
            id: 1,
            src: arrayDestruct,
            srclink:'https://github.com/ZeelPatel-0369/MultiSensory_Android_App',
            des: "Android App which connect to arduino and collect the data and process it for visulization"
        },
        // {
        //     id: 2,
        //     src: reactParallax,
        //     srclink:'https://facebook.com',
        //     des: "Android App which connect to arduino and collect the data and process it for visulization"
        // }
        // ,
        // {
        //     id: 3,
        //     src: navbar,
        //     srclink:'https://facebook.com'
        // },
        // {
        //     id: 4,
        //     src: reactSmooth,
        //     srclink:'https://facebook.com'
        // },
        // {
        //     id: 5,
        //     src: installNode,
        //     srclink:'https://instagram.com'
        // }
    ];

    return (
        <div name="portfolio" className=" lg:h-screen bg-gradient-to-b from-black to-gray-700 w-full text-white md:h-screen sm:px-20" >
            <div className=" h-full max-w-screen-2xl p-4 mx-auto flex flex-col justify-center ">

                <div className="pb-12">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-800">Portfolio</p>
                </div>


                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-10 px-12 sm:px-0 mb-20">

                    {
                        portfolios.map((port) => (
                            <div key={port.id} className="shadow-md shadow-gray-600 rounded-lg">
                                <img src={port.src} alt="" className="rounded-lg duration-200 hover:scale-105" />
                                <div className="items-center px-2 py-2">
                                    <p className="px-1 py-2">{port.des}</p>
                                    <button className="w-full  px-1 py-1 duration-200 hover:scale-105 ">
                                        <a href={port.srclink} class="relative p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md">
                                            <span class="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#fb1c1c] to-[#ead21e] group-hover:from-[#eb3d2a] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                                            <span class="relative px-3 py-1.5 transition-all ease-out bg-gray-900 rounded-md group-hover:bg-opacity-0 duration-400">
                                                <span class="relative text-white">Learn More</span>
                                            </span>
                                        </a>
                                    </button>
                                </div>
                            </div>

                        )
                        )
                    }



                </div>
            </div>

        </div>
    )
}

export default Portfolio;

import React from 'react'
import myimage2 from '../assets/ZEEL_PROFILE.jpg'
import { Link } from "react-scroll";

import { BsFillArrowRightSquareFill } from 'react-icons/bs'
const Home = () => {
    return (
        <div name="home" className="lg:h-screen w-full bg-gradient-to-b from-black via-black to-gray-700 sm:px-20">
            <div className="mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row max-w-screen-2xl pt-8 md:pt-0">
                <div  className="flex flex-col justify-center h-full   " >
                    <h2 className="text-4xl sm:text-7xl font-bold text-white animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-orange-500 bg-clip-text text-transparent">
                        Hey! Meet Zeel
                    </h2>
                    <p className=" text-gray-500 py-4 text-justify max-w-screen-2xl">
                        A highly skilled full stack software developer with a passion
                        for creating cutting-edge applications. Zeel has a strong
                        background in both front-end and back-end development, and is
                        experienced in a wide range of programming languages and frameworks.
                        Zeel has the expertise to deliver a solution that exceeds your expectations.
                        Let's dive into Zeel's impressive portfolio of work.
                    </p>
                    <div>
                        <Link to="portfolio" smooth duration={500} className=" group text-white font-semibold w-fit px-4 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-red-500 to-yellow-500 cursor-pointer">
                            Portfolio
                            <span className="group-hover:rotate-90 duration-300 ml-2">
                                <BsFillArrowRightSquareFill size={30} className="ml-1"/>
                            </span>
                        </Link>
                    </div>
                </div>

                <div className="ml-5 mr-5  items-center justify-center w-2/3 " >
                    <img src={myimage2} alt="My Profile" className=" rounded-2xl mx-auto mt-20 mb-20 md:w-full " />
                </div>
            </div>

        </div>
    )
}

export default Home
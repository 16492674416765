import React from 'react'
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import github from "../assets/github.png";
import tailwind from "../assets/tailwind.png";
import java from "../assets/java-2.png";
import cplus from "../assets/c++.png";
import csharp from "../assets/c-sharp.png";
import linux from "../assets/Linux.svg";
import uiux from "../assets/ui-ux.png";
import mysqlim from "../assets/mysql.png";
import python from "../assets/python.png";
import spring from "../assets/springboot.png";
import ds from "../assets/algorithm.png";
import php from "../assets/php-programming-language.png";


const Experience = () => {

    const techs = [
        {
            id: 1,
            src: html,
            title: "HTML",
            style: "shadow-orange-500",
        },
        {
            id: 2,
            src: css,
            title: "CSS",
            style: "shadow-blue-500",
        },
        {
            id: 3,
            src: javascript,
            title: "JavaScript",
            style: "shadow-yellow-500",
        },
        {
            id: 4,
            src: reactImage,
            title: "React",
            style: "shadow-blue-600",
        },
        {
            id: 5,
            src: tailwind,
            title: "Tailwind",
            style: "shadow-sky-400",
        },
        {
            id: 6,
            src: linux,
            title: "Linux",
            style: "shadow-green-400",
        },
        {
            id: 7,
            src: uiux,
            title: "UI/UX",
            style: "shadow-pink-400",
        },
        {
            id: 8,
            src: github,
            title: "GitHub",
            style: "shadow-gray-400",
        },
        {
            id: 9,
            src: java,
            title: "Java",
            style: "shadow-red-400",
        },
        {
            id: 10,
            src: cplus,
            title: "C++",
            style: "shadow-white",
        },
        {
            id: 11,
            src: csharp,
            title: "C#",
            style: "shadow-white",
        },
        {
            id: 12,
            src: mysqlim,
            title: "MySQL",
            style: "shadow-blue-300",
        },
        {
            id: 13,
            src: python,
            title: "Python",
            style: "shadow-yellow-800",
        },
        {
            id: 14,
            src:ds,
            title: "Data-Struture",
            style: "shadow-pink-400",
        },
        {
            id: 15,
            src:spring,
            title: "Spring-Boot",
            style: "shadow-green-400",
        },
        {
            id: 16,
            src:php,
            title: "PHP",
            style: "shadow-red-400",
        },

    ];



    return (
        <div name="experience" className=" lg:h-screen bg-gradient-to-b from-gray-700 to-black sm:px-20 w-full ">
            <br />
            <br />
            <div className=" mx-auto p-4 flex flex-col justify-center w-full h-full text-white max-w-screen-2xl">
            <div>
                <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline">
                    Experience
                </p>
                <p className="py-6 font-light text-gray-500">These are the technologies I've worked with</p>
            </div>

            <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">

                {
                    techs.map((technologies)=>(
                        <div key={technologies.id} className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${technologies.style}`}>
                        <img src={technologies.src} alt="" className="mx-auto w-20"/>
                        <p className="mt-4">{technologies.title}</p>
                    </div>

                    ))
                }


             
            </div>




            </div>
        </div>
    )
}

export default Experience
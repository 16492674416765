import React , { useState, useEffect } from 'react'
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
const Bottom = () => {


    const [showBar, setShowBar] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <1023) {
          setShowBar(true);
        } else {
          setShowBar(false);
        }
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return (
      <>
        {showBar && (
          <div className="fixed bottom-0 left-0 w-full h-16 flex justify-center items-center">
          <div className="bg-gray-800 bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-full px-8 py-2 flex items-center hover:bg-gradient-to-r from-red-500 to-yellow-500 ">
            <a href="https://www.linkedin.com/in/zeel-patel-08a839181/" className="mx-4 text-white "><FaLinkedin /></a>
            <a href="https://github.com/ZeelPatel-0369" className="mx-4 text-white"><FaGithub /></a>
            <a href="mailto:portfolio.dev@zeel-patel.com" className="mx-4 text-white"><HiOutlineMail /></a>
            <a href="/ZEEL_PATEL_Resume.pdf" download={true} className="mx-4 text-white"><BsFillPersonLinesFill /></a>
          </div>
        </div>
        )}
      </>
    );
    // return (
    //     <div>
    //         <div>
    //             <button></button>



    //         </div>

    //     </div>
    // )
}

export default Bottom
import React from 'react'

const Contact = () => {
    return (
        <div name="contact" className="w-full  bg-gradient-to-b from-black to-gray-800 p-4 text-white sm:px-20 lg:h-screen">
            <div className="flex flex-col p-4 justify-center max-w-screen-2xl mx-auto h-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">Contact</p>
                    <p className="py-6 text-gray-500">Let's Connect</p>
                </div>

                <div className=" flex ">
                    <form action="https://getform.io/f/f66f8f65-0663-419a-89dd-b9b7a8ccadeb" method="POST" className=" flex flex-col w-full md:w-1/2">
                        <input type="text" name="name" placeholder='Enter your name' className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none" />
                        <input type="text" name="email" placeholder='Enter your Email' className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none" />
                        <textarea name="message" placeholder='Type your message here' rows="10" className="p-2 bg-transparent border-2 rounded-md focus:outline-none"></textarea>
                        <button type="submit" className="my-3">
                            <a href="#_" class="relative inline-flex items-center px-12 py-1 overflow-hidden text-lg font-medium text-yellow-600 border-2 border-red-600 rounded-full hover:text-white group hover:bg-gray-50">
                                <span class="absolute left-0 block w-full h-0 transition-all bg-yellow-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span class="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span class="relative">Send</span>
                            </a>
                        </button>
                        <br />
                        <br />
                        <br />
                    </form>
                
                </div>


            </div>
        </div>
    )
}

export default Contact
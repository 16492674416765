import React from 'react'

const About = () => {
    return (
        <div name="about" className=" w-full lg:h-screen bg-gradient-to-b from-gray-700 to-black text-white sm:px-20 ">

            <div className="max-w-screen-2xl p-4 mx-auto flex flex-col justify-center w-full h-full sm:px">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-gray-500">About</p>
                </div>
                <p className="text-xl mt-15 text-justify">
                Welcome to my portfolio website! My name is Zeel, and I'm a Full Stack Developer with a recent degree from the University of Prince Edward Island, where I majored in Computer Science and minored in Mathematics. As a recent graduate, I'm eager to apply the skills and knowledge I've gained in both fields to develop innovative and impactful applications.
                </p>
                <br />
                <p className="text-xl text-justify" >

                My passion for technology and problem-solving began at a young age when I accidentally spilled juice all over my computer and had to fix it myself. It was through tinkering with the computer parts that I stumbled upon the code that powered the games I loved to play. I was fascinated by the lines of code that made the characters move and the obstacles appear on the screen.
                </p>
                <br />
                <p className="text-xl text-justify" >

                Since then, my love of coding has only grown stronger. Throughout my academic and professional experience, I've continued to develop my skills and hone my craft, always seeking to create new and exciting applications that enhance user experiences. I'm excited to continue learning and growing as a developer, and I'm looking forward to collaborating on new projects that challenge me to think outside the box.
                </p>
                <br />
                <p className="text-xl text-justify mb-20" >

                Please take a look around my portfolio, and don't hesitate to reach out if you have any questions or potential projects in mind. Thank you for visiting!
                </p>
            </div>

        </div>
    )
}


export default About